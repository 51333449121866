//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-464:_4660,_6872,_4436,_1984,_5644,_5412,_2831,_6263;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-464')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-464', "_4660,_6872,_4436,_1984,_5644,_5412,_2831,_6263");
        }
      }catch (ex) {
        console.error(ex);
      }