//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-464:_3408,_3740,_4252,_1536,_5708,_9648,_6992,_3072;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-464')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-464', "_3408,_3740,_4252,_1536,_5708,_9648,_6992,_3072");
        }
      }catch (ex) {
        console.error(ex);
      }